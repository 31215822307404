//@flow
import React from 'react'
import { css } from '@emotion/core'

import addToMailchimp from 'gatsby-plugin-mailchimp'

export const CenteredNewsLetterSignup = props => {
  const the_css = css`
        display: flex;
        justify-content: center;
        align-items: center;
        `
    return <div css={the_css}>
      {props.children}
    </div>
}

export default class NewsletterSignup extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      newsletter_submission_done:  false,
      message: ''
    }
  }
  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:

  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).


  // 1. via `.then`
  _handleSubmit = e => {
    e.preventDefault();
    const email = e.target.elements[0].value
    const listFields = {}
    addToMailchimp(email, listFields) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        const { result, msg } = data
        if(result === 'success'){
          this.setState({
            newsletter_submission_done:  true, message: 'Thank you for subscribing to my bi-daily newsletter (kidding)!'
          })
        } else {
          this.setState({
            newsletter_submission_done:  false,
            message: msg
          })
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      })
  }

  render () {
    if(this.state.newsletter_submission_done === true) {
      return this.state.message
    }
    return (
        <form
          css={css`
            display: flex;
            align-items: center;
            align-content: center;
          `}
          onSubmit={this._handleSubmit}>
          <label htmlFor="email" css={css`
            width: 200px;
            margin-right: 5px;
            margin-top: 0px;
          `}> Sign up to my newsletter </label>
          <input css={css`margin-top: 0px; margin-right: 5px`} id={'email'} placeholder={'Your email goes here'} type="email"/>
          <button
            css={css`
              width: 150px;
              margin-top: 0px;
            `}
          >
            Sign up
          </button>
        </form>
    )
  }
}
